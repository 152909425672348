
import PropertyCentroid from "~/graphql/Property/PropertyCentroid.gql";
import PropertyCentroidAndAddress from "~/graphql/Property/PropertyCentroidAndAddress.gql";
import LokalebasenQuery from "~/graphql/Property/Lokalebasen.gql";
import LokalebasenLocationsQuery from "~/graphql/Property/LokalebasenLocations.gql";
import Permissions from "~/graphql/Authentication/Permissions.gql";

import moment from "moment";

import NoAccess from "./components/NoAccess.vue";
import ModalExport from "./components/ModalExport.vue";
import ModalExportLoading from "./components/ModalExportLoading.vue";
import Pagination from "~/pages/explore/components/Pagination.vue";
import WidgetLayout from "~/components/Widget/WidgetLayout.vue";
import WidgetKeyValue from "~/components/Widget/WidgetKeyValue.vue";
import WidgetLayoutRowCellDouble from "~/components/Widget/WidgetLayoutRowCellDouble.vue";
import WidgetLayoutRowCellFourths from "~/components/Widget/WidgetLayoutRowCellFourths.vue";

import { sortByAddress } from "~/helpers/sort-helpers.js";
import { getPropertyAddress } from "~/helpers/property-helpers.js";
import { getAddressAsLongString } from "~/helpers/address-helpers.js";
import { writeSheetsToXSLXAndSave, createLokalebasenLocationsLevelSheet, createLokalebasenRentSheet } from "~/helpers/download-helpers.js";

import { filter } from "~/composables/useFilter";

export default {
  title() {
    return this.$t("LOKALEBASEN_PAGE_TEXT");
  },

  components: {
    Pagination,
    NoAccess,
    WidgetLayout,
    WidgetLayoutRowCellDouble,
    WidgetLayoutRowCellFourths,
    WidgetKeyValue,
    ModalExport,
    ModalExportLoading,
  },

  apollo: {
    me: {
      query: Permissions,
    },
  },

  data() {
    return {
      calculations: null,
      locations: null,
      loading: true,
      errorMessage: "",
      type: undefined,
      timerSearchRadius: undefined,
      timerCurrentPage: undefined,
      timerAreaMin: undefined,
      timerAreaMax: undefined,
      resultsPerPage: 10,
      resultsCurrentPage: 0,
      resultsLength: 0,
      kindsDropdownData: ["office", "business_center", "warehouse", "store", "coworking"],
      activeStateData: [
        {
          label: this.$t("LOKALEBASEN_FILTER_STATUS_NOTHING_CHOSEN"),
          value: undefined,
        },
        { label: this.$t("LOKALEBASEN_STATE_ACTIVE"), value: "ACTIVE" },
        { label: this.$t("LOKALEBASEN_STATE_CLOSED"), value: "CLOSED" },
      ],
      chartData: {},
      hasValidInput: true,
      exportLoading: false,
      modalExportActive: false,
    };
  },

  computed: {
    filter() {
      return filter;
    },

    hasAccess: function () {
      return this.me?.permissions?.some((p) => p.indexOf("LOKALE_BASEN:READ:ORG") != -1);
    },

    searchRadius: {
      get() {
        return this.$store.getters["mapstate/lokalebasenSearchRadius"];
      },

      set(val) {
        this.$store.commit("mapstate/lokalebasenSearchRadius", val);
      },
    },

    dateFrom: {
      get() {
        return this.$store.getters["mapstate/lokalebasenDateFrom"];
      },

      set(val) {
        this.$store.commit("mapstate/lokalebasenDateFrom", val);
      },
    },

    dateTo: {
      get() {
        return this.$store.getters["mapstate/lokalebasenDateTo"];
      },

      set(val) {
        this.$store.commit("mapstate/lokalebasenDateTo", val);
      },
    },

    kinds: {
      get() {
        return this.$store.getters["mapstate/lokalebasenKinds"];
      },

      set(val) {
        this.$store.commit("mapstate/lokalebasenKinds", val);
      },
    },

    areaMin: {
      get() {
        return this.$store.getters["mapstate/lokalebasenAreaMin"];
      },

      set(val) {
        this.$store.commit("mapstate/lokalebasenAreaMin", val);
      },
    },

    areaMax: {
      get() {
        return this.$store.getters["mapstate/lokalebasenAreaMax"];
      },

      set(val) {
        this.$store.commit("mapstate/lokalebasenAreaMax", val);
      },
    },

    activeState: {
      get() {
        return this.$store.getters["mapstate/lokalebasenActiveState"];
      },

      set(val) {
        this.$store.commit("mapstate/lokalebasenActiveState", val);
      },
    },
  },

  watch: {
    async searchRadius() {
      this.clearTimeouts();

      this.timerSearchRadius = setTimeout(async () => {
        await this.fetchResults();
      }, 1000);
    },

    async dateFrom() {
      this.clearTimeouts();

      await this.fetchResults();
    },

    async dateTo() {
      this.clearTimeouts();

      await this.fetchResults();
    },

    async kinds() {
      this.clearTimeouts();

      await this.fetchResults();
    },

    async areaMin() {
      this.clearTimeouts();

      this.timerAreaMin = setTimeout(async () => {
        await this.fetchResults();
      }, 1000);
    },

    async areaMax() {
      this.clearTimeouts();

      this.timerAreaMax = setTimeout(async () => {
        await this.fetchResults();
      }, 1000);
    },

    async activeState() {
      this.clearTimeouts();

      await this.fetchResults();
    },
  },

  async mounted() {
    await this.fetchResults();
  },

  methods: {
    moment: moment,

    sortByAddress: sortByAddress,

    dropDownLabel(value) {
      return this.activeStateData.find((item) => item.value?.toLowerCase() === value?.toLowerCase()).label;
    },

    async pageNext() {
      this.resultsCurrentPage = this.resultsCurrentPage + 1;

      await this.fetchResults();
    },

    async pagePrevious() {
      this.resultsCurrentPage = this.resultsCurrentPage - 1;

      await this.fetchResults();
    },

    async pageChange(n) {
      clearTimeout(this.timerSearchRadius);
      clearTimeout(this.timerCurrentPage);

      this.timerCurrentPage = setTimeout(async () => {
        this.resultsCurrentPage = n - 1;

        await this.fetchResults();
      }, 600);
    },

    async fetchResults() {
      if (!this.hasValidInput) {
        return;
      }

      this.loading = true;

      const bfeNumber = parseInt(this.$router.history?.current?.params?.id, 10);

      if (!bfeNumber) {
        this.errorMessage = this.$t("LOKALEBASEN_ERROR_BFENUMBER");
        this.loading = false;
        return;
      }

      const propertyCentroidResult = await this.$apollo.query({
        query: PropertyCentroid,
        variables: {
          bfeNumber: bfeNumber,
        },
      });

      const centroid = propertyCentroidResult.data?.propertyByBFENumber?.centroid;

      if (!centroid) {
        // this.errorMessage = this.$t("LOKALEBASEN_ERROR_CENTROID");
        this.loading = false;
        return;
      }

      let lokalebasenResult;

      try {
        lokalebasenResult = await this.$apollo.query({
          query: LokalebasenQuery,
          variables: {
            input: {
              centroid: { lat: centroid.lat, lon: centroid.lng },
              dateFrom: moment(this.dateFrom).utc().format(),
              dateTo: moment(this.dateTo).utc().format(),
              radius: this.searchRadius / 1000 - 0.0000000001,
              limit: this.resultsPerPage,
              offset: this.resultsCurrentPage * this.resultsPerPage,
              kinds: this.kinds.map((kind) => kind.toUpperCase()),
              areaMax: parseInt(this.areaMax, 10),
              areaMin: parseInt(this.areaMin, 10),
              state: this.activeState,
            },
          },
          errorPolicy: "ignore",
        });
      } catch (error) {
        this.errorMessage = `${this.$t("LOKALEBASEN_ERROR_LOKALEBASEN_RESULT")}: ${error.message}`;
        this.loading = false;
        return;
      }

      this.calculations = lokalebasenResult.data?.lokaleBasenRent?.calculations ?? [];
      this.locations = lokalebasenResult.data?.lokaleBasenRent?.locations ?? [];

      const kindDistribution = this.calculations.kindDistribution;
      let chartData = {};

      if (kindDistribution != undefined) {
        chartData = {
          labels: [...Object.keys(kindDistribution).map((key) => this.translateKind(key))],
          series: [...Object.keys(kindDistribution).map((key) => kindDistribution[key])],
        };
      } else {
        chartData = {
          labels: [],
          series: [],
        };
      }

      this.chartData = chartData;

      this.resultsLength = lokalebasenResult.data?.lokaleBasenRent?.totalResults ?? 0;

      this.loading = false;
    },

    determineIcon(value) {
      if (typeof value == "number" && value !== 0) {
        return value > 0 ? "arrow_upward" : "arrow_downward";
      } else {
        return "";
      }
    },

    determineIconColor(value, upwardsTrendPositive) {
      if (typeof value == "number" && value !== 0) {
        return (upwardsTrendPositive ? value > 0 : value < 0) ? "green" : "pink";
      } else {
        return "";
      }
    },

    translateState(value) {
      switch (value) {
        case "active":
          return this.$t("LOKALEBASEN_STATE_ACTIVE");
        case "closed":
          return this.$t("LOKALEBASEN_STATE_CLOSED");
        default:
          return this.$t("LOKALEBASEN_STATE_DEFAULT");
      }
    },

    translateKind(value) {
      switch (value) {
        case "office":
          return this.$t("LOKALEBASEN_KIND_OFFICE");
        case "business_center":
          return this.$t("LOKALEBASEN_KIND_BUSINESS_CENTER");
        case "warehouse":
          return this.$t("LOKALEBASEN_KIND_WAREHOUSE");
        case "store":
          return this.$t("LOKALEBASEN_KIND_STORE");
        case "coworking":
          return this.$t("LOKALEBASEN_KIND_COWORKING");
        case "meeting_room":
          return this.$t("LOKALEBASEN_KIND_MEETING_ROOM");
        case "virtual_office":
          return this.$t("LOKALEBASEN_KIND_VIRTUAL_OFFICE");
        case "parking":
          return this.$t("LOKALEBASEN_KIND_PARKING");
        default:
          return this.$t("LOKALEBASEN_KIND_DEFAULT");
      }
    },

    kindsUpdated(value) {
      this.kinds = value;
    },

    clearTimeouts() {
      clearTimeout(this.timerSearchRadius);
      clearTimeout(this.timerCurrentPage);
      clearTimeout(this.timerAreaMax);
      clearTimeout(this.timerAreaMin);
    },

    validateAreaInput(value) {
      return /^[0-9]{0,6}$/.test(value);
    },

    async exportRefereceData() {
      this.modalExportActive = false;

      if (!this.hasValidInput) {
        this.modalExportActive = false;
        return;
      }

      const bfeNumber = parseInt(this.$router.history?.current?.params?.id, 10);

      if (!bfeNumber) {
        this.errorMessage = this.$t("LOKALEBASEN_ERROR_BFENUMBER");
        this.modalExportActive = false;
        return;
      }

      this.exportLoading = true;

      const propertyCentroidAndAddressResult = await this.$apollo.query({
        query: PropertyCentroidAndAddress,
        variables: {
          bfeNumber: bfeNumber,
        },
      });

      const centroid = propertyCentroidAndAddressResult.data?.propertyByBFENumber?.centroid;

      if (!centroid) {
        this.exportLoading = false;
        return;
      }

      let lokalebasenResult;

      try {
        lokalebasenResult = await this.$apollo.query({
          query: LokalebasenLocationsQuery,
          variables: {
            input: {
              centroid: { lat: centroid.lat, lon: centroid.lng },
              dateFrom: moment(this.dateFrom).utc().format(),
              dateTo: moment(this.dateTo).utc().format(),
              radius: this.searchRadius / 1000 - 0.0000000001,
              kinds: this.kinds.map((kind) => kind.toUpperCase()),
              areaMax: parseInt(this.areaMax, 10),
              areaMin: parseInt(this.areaMin, 10),
              state: this.activeState,
            },
          },
          errorPolicy: "ignore",
        });
      } catch (error) {
        this.exportLoading = false;
        this.errorMessage = `${this.$t("LOKALEBASEN_ERROR_LOKALEBASEN_RESULT")}: ${error.message}`;
        return;
      }

      let rentCalculations = [this.calculations];
      let locations = lokalebasenResult.data?.lokaleBasenRent?.locations ?? [];

      // Concat images array in locations
      locations = locations.map((location) => {
        const pictures = location.pictures.map((image) => image).join(", ");
        return { ...location, pictures };
      });

      // Get the address
      let addressString = "";
      const address = getPropertyAddress(propertyCentroidAndAddressResult.data?.propertyByBFENumber);
      if (address != null) {
        addressString = getAddressAsLongString(address);
      }

      const rentalSheet = createLokalebasenRentSheet(rentCalculations, this.$t("COMMERCIAL_RENT_EXPORT_SHEET_NAME_CALCULATIONS"), this.$i18n.locale);
      const locationsSheet = createLokalebasenLocationsLevelSheet(locations, this.$t("COMMERCIAL_RENT_EXPORT_SHEET_NAME_REFERENCES"), this.$i18n.locale);

      this.exportLoading = false;

      writeSheetsToXSLXAndSave([rentalSheet, locationsSheet], this.$t("COMMERCIAL_RENT_EXPORT_NAME", { address: addressString }));
    },
  },
};
